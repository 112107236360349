import { memo } from "react"
import { formatHours } from "../../.."
import { formatCurrency } from "../../../../../helpers/number.helper"
import { bodyStyle, columnWidth, payStubItemHeader, payStubSection, paymentDetailsTableCell, paymentDetailsTableFirstCell, paymentDetailsTableGrayRow, paymentDetailsTableHightlightRow, paymentDetailsTableTotalsRow, paymentDetailsTableWhiteRow } from "../../../styles"

const PayStubDetailsTable = ({details, index, showFacilityHeader, showTotals}) => {
  const getRowStyles = (index) => {
    return index % 2 === 0 ? paymentDetailsTableWhiteRow : paymentDetailsTableGrayRow
  }

  return (
    <html>
      <body style={bodyStyle}>
        {index === 0 ? (
          <div style={{width: '100%'}}>
            <div 
              style={payStubSection}
            >
              {`Transactions`}
            </div>
          </div>
        ) : null}

        {showFacilityHeader ? (
          <div style={{width: '100%'}}>
            <div 
              style={payStubItemHeader}
            >
              <div 
                style={{flex: 1}}
              >
                {`Facility: ${details.facility_name}`}
              </div>
              
              <div 
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {`Shift Date: ${details.shift_date}`}
              </div>
              
              <div 
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center', 
                  textAlign: 'right',
                }}
              >
                {`Shift: ${formatHours(details.start_time)} to ${formatHours(details.end_time)}`}
              </div>
            </div>

            <div style={paymentDetailsTableHightlightRow}>
              <div style={{
                ...paymentDetailsTableFirstCell,
              }}>
                {`Pay Type`}	
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.hours,
              }}>
                {`Hours`}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.amount,
              }}>
                {`Pay Amount`}
              </div>
            </div>
          </div>
        ) : null}

        <div style={getRowStyles(index)}>
          <div style={{
            ...paymentDetailsTableFirstCell,
          }}>
            {details.pay_type_name}
          </div>
          
          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.hours,
          }}>
            {formatCurrency(details.hours_worked)}
          </div>

          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.amount,
          }}>
            {`$`}{formatCurrency(details.pay_amount)}
          </div>
        </div>
        
        {showTotals ? (
          <div style={{width: '100%'}}>
            <div style={paymentDetailsTableTotalsRow}>
              <div style={{
                ...paymentDetailsTableFirstCell,
              }}>
                {`Totals`}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.hours,
              }}>
                {formatCurrency(details.total_hours)}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.amount,
              }}>
                {`$`}{formatCurrency(details.total_amount)}
              </div>
            </div>
          </div>
        ) : null}
      </body>
    </html>
  )
}

export default memo(PayStubDetailsTable)
