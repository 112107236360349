import { CSSProperties, FC, memo } from "react";

interface InvoiceTotalTableProps {
  isCreditMemo: boolean;
  totalHours: string;
  invoiceTotal: string
}

const InvoiceTotalTable: FC<InvoiceTotalTableProps> = ({isCreditMemo, totalHours, invoiceTotal}) => {
  const invoiceMainRangeTextStyle: CSSProperties = {
    fontSize: '10px',
    marginBottom: 4,
  }

  const invoiceMainTableRowStyle: CSSProperties = {
    display: 'flex', 
    flexDirection: 'row',
    marginBottom: 10
  }

  const invoiceAmountsTableCell: CSSProperties = {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 1,
    paddingBottom: 1,
    borderLeft: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
  }

  const invoiceAmountsTableRow: CSSProperties = {
    ...invoiceMainTableRowStyle,
    fontSize: '10px',
    marginBottom: 0,
  }

  const invoiceAmountsTextTableCell: CSSProperties = {
    ...invoiceAmountsTableCell,
    justifyContent: 'flex-start',
    textAlign: 'left'
  }

  const invoiceAmountsTableFirstCell: CSSProperties = {
    ...invoiceAmountsTextTableCell,
    borderLeftWidth: 0,
  }

  const invoiceAmountsTableHightlightRow: CSSProperties = {
    ...invoiceAmountsTableRow,
    border: '1px solid black',
    // backgroundColor: '#2b78e4', // blue
    // backgroundColor: '#fb6a2f', // orange
    backgroundColor: '#f04f23', // darker orange
    color: 'white'
  }

  const invoiceAmountsTableWhiteRow: CSSProperties = {
    ...invoiceAmountsTableRow,
    backgroundColor: 'white',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
  }

  const columnWidth = {
    serviceDateRange: 130,
    payType: 100,
    hours: 80,
    amount: 100,
  }
  
  return (
    <div>
      <div style={{
        ...invoiceMainRangeTextStyle,
        marginTop: 10
      }}><strong>{`${isCreditMemo ? 'Credit Memo' : 'Invoice'} Total`}</strong></div>
                      
      <div style={invoiceAmountsTableHightlightRow}>
        <div 
          style={{
            ...invoiceAmountsTableFirstCell,
            width: columnWidth.hours,
          }}
        >
          {`Hours`}
        </div>
        <div 
          style={{
            ...invoiceAmountsTableCell,
            width: columnWidth.amount,
          }}
        >
          {`Amount`}
        </div>
      </div>
      <div style={invoiceAmountsTableWhiteRow}>
        <div style={{
          ...invoiceAmountsTableFirstCell,
          width: columnWidth.hours,
        }}>{totalHours}</div>
        <div style={{
          ...invoiceAmountsTableCell,
          width: columnWidth.amount,
        }}>${invoiceTotal}</div>
      </div>
    </div>
  )
}

export default memo(InvoiceTotalTable);
