import { BlobProvider, Document } from "@react-pdf/renderer";
import axios from "axios";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { FacilityAddress, FetchFacilityInvoiceDataResponse } from "../../../../@types/facility";
import FirstPage from "./Pages/FirstPage";
import SecondPage from "./Pages/SecondPage";
import TimesheetPDF from "./TimesheetPDF";

const API_ROOT = '/api/v1';

const InvoicePDF: FC<{data: FetchFacilityInvoiceDataResponse}> = ({data}) => {
  return (
    <Document>
      <FirstPage {...data} />
      {data.entries.length > 0 ? <SecondPage {...data} /> : null}
    </Document>
  )
}

// const TimesheetPDFViewer: FC<{data: FetchFacilityInvoiceDataResponse}> = ({data}) => {
//   return (
//     <div style={{
//       width: '100%',
//       height: 'auto'
//     }}>
//       <PDFViewer 
//         showToolbar={true}
//         style={{ 
//           width: '100%',
//           height: '100vh'
//         }}
//       >
//         <TimesheetPDF {...data} />
//       </PDFViewer>
//     </div>
//   )
// }

// const InvoicePDFViewer: FC<{data: FetchFacilityInvoiceDataResponse}> = ({data}) => {
//   return (
//     <div style={{
//       width: '100%',
//       height: 'auto'
//     }}>
//       <PDFViewer 
//         showToolbar={true}
//         style={{ 
//           width: '100%',
//           height: '100vh'
//         }}
//       >
//         <InvoicePDF data={data} />
//       </PDFViewer>
//     </div>
//   )
// }

const InvoicePDFGenerator: FC = () => {
  const [pdfToken, setPDFToken] = useState<string>('')
  const [authToken, setAuthToken] = useState<string>('')
  const [facilityApiURL, setFacilityApiURL] = useState<string>('')
  const [companyAddress, setCompanyAddress] = useState<FacilityAddress>()
  const [data, setData] = useState<FetchFacilityInvoiceDataResponse>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [invoicePDFBlob, setInvoicePDFBlob] = useState<Blob | null>(null)
  const [timesheetPDFBlob, setTimesheetPDFBlob] = useState<Blob | null>(null)

  const saveBlob = useCallback((blob: Blob | null) => {
    // TODO: throw an error in case blob is null
    if (!blob) return

    setInvoicePDFBlob(blob)
  }, [])

  const saveTimesheetBlob = useCallback((blob: Blob | null) => {
    // TODO: throw an error in case blob is null
    if (!blob) return

    setTimesheetPDFBlob(blob)
  }, [])

  const setTokens = useCallback(() => {
    const authToken = (document?.getElementById('auth-token-input') as HTMLInputElement)?.value ?? ''
    const pdfToken = (document?.getElementById('pdf-token-input') as HTMLInputElement)?.value ?? ''
    const facilityApiURL = (document?.getElementById('facility-api-url-input') as HTMLInputElement)?.value ?? ''
    const companyAddress = JSON.parse((document?.getElementById('set-company-address') as HTMLInputElement)?.value ?? '{}')

    setAuthToken(authToken)
    setPDFToken(pdfToken)
    setFacilityApiURL(facilityApiURL)
    setCompanyAddress(companyAddress)
  }, [])

  useEffect(() => {
    if (!invoicePDFBlob || !timesheetPDFBlob) return

    const invoicePDFFile = new File([invoicePDFBlob], `invoice.pdf`)
    const timesheetPDFFile = new File([timesheetPDFBlob], `timesheet.pdf`)
    const formData = new FormData()
    formData.set('invoiceFile', invoicePDFFile)
    formData.set('timesheetFile', timesheetPDFFile)
    formData.append('token', pdfToken)
    formData.append('facilityApiURL', facilityApiURL)

    axios.post(`${API_ROOT}/facility/saveGeneratedInvoicePDF`, formData, {
      headers: {
        'authorization': authToken,
        'Content-Type': `multipart/form-data`
      },
    })
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.error(e)
      })
  }, [invoicePDFBlob, pdfToken, authToken, facilityApiURL, timesheetPDFBlob])

  useEffect(() => {
    if (pdfToken && pdfToken.length > 0 && authToken && authToken.length > 0 && facilityApiURL && facilityApiURL.length > 0 && companyAddress) {
      (async () => {
        try {
          const res = await fetch(`${API_ROOT}/facility/fetchInvoiceDataByToken?token=${pdfToken}&facilityApiURL=${facilityApiURL}`, {
            headers: {
              'authorization': authToken,
              'Content-Type': 'application/json'
            }
          })
          
          try {
            const data = await res.json()
            console.log('[fetchInvoiceDataByToken] data', data)
            setData({
              ...data.data,
              companyAddress
            })
          } catch (e) {
            console.error(e)
            let newErrorMessage = (e as Error).message ?? 'Something went wrong'
            newErrorMessage += ' ' + await res.text()
            setErrorMessage(newErrorMessage)
          }
        } catch (e) {
          console.error(e)
          setErrorMessage((e as Error).message ?? 'Something went wrong 2')
        }
      })()
    }
  }, [pdfToken, authToken, facilityApiURL, companyAddress])

  if (!authToken || authToken.length === 0 || !pdfToken || pdfToken.length === 0 || !facilityApiURL || facilityApiURL.length === 0) {
    return (
      <div>
        <input id='auth-token-input' />
        <input id='pdf-token-input' />
        <input id='facility-api-url-input' />
        <input id='set-company-address' />
        <button id="set-tokens-btn" onClick={setTokens}>
          Set Tokens
        </button>
      </div>
    )
  }

  if (!data) {
    return <div id='loading'>Loading PDF...</div>
  }

  if (errorMessage.length > 0) {
    return <div id='error'>{errorMessage}</div>
  }

  return (
    <div>
      <BlobProvider document={<InvoicePDF data={data} />}>
        {({ blob, loading, error }) => {
            if (loading || (!error && (!blob || !data))) {
              return <div id="loading">Loading PDF...</div>
            }

            if (error) {
              return <div id="error">{error.message}</div>
            }

            return (
              <button id="save-pdf-button" onClick={() => saveBlob(blob)}>
                Save Invoice PDF
              </button>
            )
          }
        }
      </BlobProvider>

      <BlobProvider document={<TimesheetPDF {...data} />}>
        {({ blob, loading, error }) => {
            if (loading || (!error && (!blob || !data))) {
              return null
            }

            if (error) {
              return null
            }

            return (
              <button id="save-timesheet-button" onClick={() => saveTimesheetBlob(blob)}>
                Save Timesheet PDF
              </button>
            )
          }
        }
      </BlobProvider>

      {/* <InvoicePDFViewer data={data} /> */}

      {/* <TimesheetPDFViewer data={data} /> */}
    </div>
  )
}

export default memo(InvoicePDFGenerator)
