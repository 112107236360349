import { FC, memo, CSSProperties } from "react"
import { SecondPageHeaderProps } from "./types"

const SecondPageHeader: FC<SecondPageHeaderProps> = ({invoiceNumber}) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }

  const headerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 0,
  }

  return (
    <html>
      <body style={bodyStyle}>
        <div style={headerStyle}>
          <img
            style={{ width: 50 }}
            src="/img/bravoCareLogo_desktop.png"
            alt="BravoCare Logo"
          />

          <div
            style={{
              fontSize: '12px',
            }}
          >
            Invoice #: {invoiceNumber} - Details
          </div>
        </div>
      </body>
    </html>
  )
}

export default memo(SecondPageHeader)
