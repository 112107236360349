import { BlobProvider, Document, PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import { memo, useCallback, useState } from "react";
import { ProfessionalPayHistory } from "../../../../@types/professional";
import FirstPage from "./Pages/FirstPage";

const API_ROOT = '/api/v1';

const PayHistoryPDF = ({data}) => {
  return (
    <Document>
      <FirstPage {...data} />
    </Document>
  )
}

export const PayHistoryPDFViewer = ({data}) => {
  return (
    <div style={{
      width: '100%',
      height: 'auto'
    }}>
      <PDFViewer 
        showToolbar={true}
        style={{ 
          width: '100%',
          height: '100vh'
        }}
      >
        <PayHistoryPDF data={data} />
      </PDFViewer>
    </div>
  )
}

const PayHistoryPDFGenerator = () => {
  const [pdfToken, setPDFToken] = useState('')
  const [authToken, setAuthToken] = useState('')
  const [data, setData] = useState<ProfessionalPayHistory>()
  const [professionalApiURL, setProfessionalApiURL] = useState('')

  const passthroughBlob = useCallback((blob: Blob | null) => {
    // TODO: throw an error in case blob is null
    if (!blob) return

    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => {
      window['blobBase64'] = reader.result
    }
    reader.onerror = (error) => {
      console.log('Error: ', error)
    }
  }, [])

  const saveBlob = useCallback((blob: Blob | null) => {
    // TODO: throw an error in case blob is null
    if (!blob) return

    passthroughBlob(blob)

    const file = new File([blob], `payhistory.pdf`)

    const formData = new FormData()
    formData.set('file', file)
    formData.append('token', pdfToken)
    formData.append('professionalApiURL', professionalApiURL)

    axios.post(`${API_ROOT}/professional/saveGeneratedPayHistoryPDF`, formData, {
      headers: {
        'authorization': authToken,
        'Content-Type': `multipart/form-data`
      },
    })
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.error(e)
      })
  }, [pdfToken, authToken, professionalApiURL, passthroughBlob])

  const setTokens = useCallback(() => {
    const authToken = (document.getElementById('auth-token-input') as HTMLInputElement)?.value ?? ''
    const pdfToken = (document.getElementById('pdf-token-input') as HTMLInputElement)?.value ?? ''
    const data = (document.getElementById('data-input') as HTMLInputElement)?.value ?? ''
    const professionalApiURL = (document.getElementById('prof-api-url-input') as HTMLInputElement)?.value ?? ''

    setAuthToken(authToken)
    setPDFToken(pdfToken)
    setData(JSON.parse(data))
    setProfessionalApiURL(professionalApiURL)
  }, [])

  if (!authToken || authToken.length === 0 || !pdfToken || pdfToken.length === 0 || !data || !professionalApiURL || professionalApiURL.length === 0) {
    return (
      <div>
        <input id='auth-token-input' />
        <input id='pdf-token-input' />
        <input id='data-input' />
        <input id='prof-api-url-input' />
        <button id="set-tokens-btn" onClick={setTokens}>
          Set Tokens
        </button>
      </div>
    )
  }

  if (!data || !professionalApiURL || professionalApiURL.length === 0) {
    return <div id='loading'>Loading PDF...</div>
  }

  return (
    <div>
      <BlobProvider document={<PayHistoryPDF data={data} />}>
        {({ blob, loading, error }) => {
            if (loading) {
              return <div id="loading">Loading PDF...</div>
            }

            if (error) {
              return <div id="error">{error.message}</div>
            }

            return (
              <div>
                <button id="save-pdf-button" onClick={() => saveBlob(blob)}>
                  Save PDF
                </button>
                <button id="passthrough-pdf-button" onClick={() => passthroughBlob(blob)}>
                  Passthrough PDF
                </button>
              </div>
            )
          }
        }
      </BlobProvider>

      {/* <PayHistoryPDFViewer data={data} /> */}
    </div>
  )
}

export default memo(PayHistoryPDFGenerator)
