import { Page, View } from "@react-pdf/renderer"
import { differenceInMinutes } from "date-fns"
import { isNil, startCase, toLower } from "lodash"
import moment from "moment"
import { CSSProperties, FC, memo } from "react"
import ReactDOMServer from "react-dom/server"
import Html from "react-pdf-html"
import { FetchFacilityInvoiceDataResponse, ProcessedPayrollData } from "../../../../../../@types/facility"
import { formatCurrency } from "../../../../helpers/number.helper"
import SecondPageFooter from "./SecondPageFooter"
import SecondPageHeader from "./SecondPageHeader"

const SecondPage: FC<FetchFacilityInvoiceDataResponse> = ({invoice, payrolls, signatureUrls, companyAddress}) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }

  const mainContentStyle: CSSProperties = {
    width: '100%',
    fontSize: '6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }

  const sectionTitleStyle: CSSProperties = {
    fontSize: '10px',
    marginTop: 10,
    marginBottom: 5,
    width: '100%',
  }

  const sectionDateDivider: CSSProperties = {
    width: '100%',
    padding: 4,
    backgroundColor: '#d3d3d3',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
    fontSize: '8px',
  }

  const professionalTableStyle: CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    margin: 0,
    padding: 0,
  }

  const professionalSubTable: CSSProperties = {
    width: 160,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    borderLeft: '0.25px solid black',
    borderRight: '0.25px solid black'
  }

  const professionalTableRow: CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    margin: 0,
    padding: 0,
  }

  const subRowHeight = 10

  const professionalTableSubRow: CSSProperties = {
    ...professionalTableRow,
    flex: 1,
    border: 0,
    height: subRowHeight,
  }

  const professionalTableOddRow: CSSProperties = {
    // backgroundColor: '#d3d3d3'
  }

  const professionalTableCell: CSSProperties = {
    border: '0.25px solid black',
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 1,
    paddingBottom: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '5px',
  }

  const professionalTableLastRowCell: CSSProperties = {
    borderBottom: '0.5px solid black'
  }

  const professionalTableHighlightCell: CSSProperties = {
    ...professionalTableCell,
    // backgroundColor: '#2b78e4',
    // color: 'white',
    backgroundColor: '#e1e1e1',
    color: 'black',
    borderTop: '0.5px solid black',
  }

  const professionalTableFirstHighlightCell: CSSProperties = {
    ...professionalTableHighlightCell,
    flex: 1,
    borderLeft: '0.5px solid black'
  }

  const professionalTableNumericHighlightCell: CSSProperties = {
    ...professionalTableHighlightCell,
    justifyContent: 'flex-end',
  }

  const professionalTableCenteredHighlightCell: CSSProperties = {
    ...professionalTableHighlightCell,
    justifyContent: 'center',
  }

  const professionalTableCenteredCell: CSSProperties = {
    ...professionalTableCell,
    justifyContent: 'center',
  }

  const professionalTableNumericCell: CSSProperties = {
    ...professionalTableCell,
    justifyContent: 'flex-end',
  }

  const professionalTableTextCell: CSSProperties = {
    ...professionalTableCell,
    justifyContent: 'flex-start',
  }

  const professionalTableFirstTextCell: CSSProperties = {
    ...professionalTableTextCell,
    flex: 1,
    borderLeft: '0.5px solid black'
  }

  const professionalTableSubRowCell: CSSProperties = {
    border: 'none !important'
  }

  const columnWidth = {
    profName: 50,
    amount: 40,
    rate: 40,
    hours: 30,
    payType: 50,
    supervisor: 60,
    totalBreaks: 35,
    clock: 30,
    date: 35,
    type: 35,
  }

  const professionalTableSpacer: CSSProperties = {
    width: 3,
    backgroundColor: 'white',
  }

  const signatureCellStyle: CSSProperties = {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const footerViewStyle: any = {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 10
  }

  const calcTotalBreak = (start: string, end: string) => {
    if (isNil(start) || isNil(end)) {
      return 0
    }
  
    return differenceInMinutes(new Date(end), new Date(start))
  }

  let [mainPayrolls, previousPayrolls] = payrolls.reduce((result: Array<{
    total_hours: 0,
    total_amount: 0,
    dates: {
      [date: string]: {
        total_breaks: number,
        total_hours: number,
        total_amount: number,
        items: {
          [key: string]: ProcessedPayrollData,
        }
      }
    }
  }>, payroll) => {
    const payrollKey = `${payroll.professional_id}-${payroll.order_id}-${payroll.entry_id}-${payroll.shift_date}`

    const mainData = {
      payroll_key: payrollKey,
      professional_id: payroll.professional_id,
      prof_full_name: startCase(toLower(payroll.prof_full_name)),
      order_id: payroll.order_id,
      entry_id: payroll.entry_id,
      shift_date: payroll.shift_date,
      adj_clock_in: payroll.adj_clock_in,
      adj_clock_out: payroll.adj_clock_out,
      clock_in_supervisor_full_name: startCase(toLower(payroll.clock_in_supervisor_full_name)),
      clock_in_signature_id: payroll.clock_in_signature_id,
      clock_in_signature_file_path: payroll.clock_in_signature_file_path,
      clock_out_supervisor_full_name: startCase(toLower(payroll.clock_out_supervisor_full_name)),
      clock_out_signature_file_path: payroll.clock_out_signature_file_path,
      job_type_id: payroll.job_type_id,
      job_type_abbrev: payroll.job_type_abbrev,
      total_break: 
        calcTotalBreak(payroll.adj_break_1_start, payroll.adj_break_1_end),
      sub_payrolls: []
    }

    if (mainData.clock_in_supervisor_full_name.includes('Bravo') && mainData.clock_in_supervisor_full_name.includes('Care')) {
      mainData.clock_in_supervisor_full_name = mainData.clock_in_supervisor_full_name.replace('Bravo', 'Missed Punch').replace('Care', 'Form')
    }

    if (mainData.clock_out_supervisor_full_name.includes('Bravo') && mainData.clock_out_supervisor_full_name.includes('Care')) {
      mainData.clock_out_supervisor_full_name = mainData.clock_out_supervisor_full_name.replace('Bravo', 'Missed Punch').replace('Care', 'Form')
    }

    const subData = {
      sub_payroll_key: payrollKey + `-${payroll.pay_type_id}`,
      hours_worked: payroll.hours_worked,
      has_worked_hours: payroll.has_worked_hours,
      pay_amount: payroll.pay_amount,
      pay_type_id: payroll.pay_type_id,
      pay_type_name: payroll.pay_type_name,
      hourly_rate: payroll.hourly_rate,
    }

    const subDataWorkedHours = parseFloat((!isNil(subData.hours_worked) ? subData.hours_worked : 0).toString())
    const subDataPayAmount = parseFloat((!isNil(subData.pay_amount) ? subData.pay_amount : 0).toString())

    if (new Date(mainData.shift_date).getTime() < new Date(invoice.week_start_date).getTime()) {
      // previousPayrolls

      if (!result[1].dates[mainData.shift_date]) {
        result[1].dates[mainData.shift_date] = {
          total_breaks: 0,
          total_hours: 0,
          total_amount: 0,
          items: {}
        }
      }

      if (!result[1].dates[mainData.shift_date].items[payrollKey]) {
        result[1].dates[mainData.shift_date].items[payrollKey] = mainData
        result[1].dates[mainData.shift_date].total_breaks += mainData.total_break
      }

      result[1].dates[mainData.shift_date].items[payrollKey].sub_payrolls.push(subData)
      result[1].dates[mainData.shift_date].total_hours += subDataWorkedHours
      result[1].dates[mainData.shift_date].total_amount += subDataPayAmount

      result[1].total_hours += subDataWorkedHours
      result[1].total_amount += subDataPayAmount
    } else {
      // mainPayrolls

      if (!result[0].dates[mainData.shift_date]) {
        result[0].dates[mainData.shift_date] = {
          total_breaks: 0,
          total_hours: 0,
          total_amount: 0,
          items: {}
        }
      }

      if (!result[0].dates[mainData.shift_date].items[payrollKey]) {
        result[0].dates[mainData.shift_date].items[payrollKey] = mainData
        result[0].dates[mainData.shift_date].total_breaks += mainData.total_break
      }

      result[0].dates[mainData.shift_date].items[payrollKey].sub_payrolls.push(subData)
      result[0].dates[mainData.shift_date].total_hours += subDataWorkedHours
      result[0].dates[mainData.shift_date].total_amount += subDataPayAmount

      result[0].total_hours += subDataWorkedHours
      result[0].total_amount += subDataPayAmount
    }

    return result
  }, [{
    total_hours: 0,
    total_amount: 0,
    dates: {}
  }, {
    total_hours: 0,
    total_amount: 0,
    dates: {}
  }])

  return (
    <Page size='LETTER' orientation="portrait" style={{ paddingVertical: 8, paddingHorizontal: 8, paddingBottom: 40 }}>
      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(<SecondPageHeader invoiceNumber={invoice.invoice_no} />)}
        </Html>
      </View>

      {Object.keys(previousPayrolls.dates).map((previousPayrollShiftDate, shiftDateIndex, mainArray) => Object.keys(previousPayrolls.dates[previousPayrollShiftDate].items).map((payrollKey, payrollIndex, array) => {
        const profFullNameNeededLines = Math.ceil(previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].prof_full_name.length / 26)
        const clockInSupervisorFullNameNeededLines = Math.ceil(previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].clock_in_supervisor_full_name.length / 20)
        const clockOutSupervisorFullNameNeededLines = Math.ceil(previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].clock_out_supervisor_full_name.length / 20)
        const subRowsLength = previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].sub_payrolls.length

        const rowsCount = Math.max(...[
          profFullNameNeededLines,
          clockInSupervisorFullNameNeededLines,
          clockOutSupervisorFullNameNeededLines,
          subRowsLength,
        ])

        return (
          <View wrap={false} key={payrollKey}>
            <Html resetStyles>
              {ReactDOMServer.renderToStaticMarkup(
                <html>
                  <body style={bodyStyle}>
                    <div style={mainContentStyle}>

                      {shiftDateIndex === 0 && payrollIndex === 0 ? (
                        <div style={{width: '100%'}}>
                          <div style={{...sectionTitleStyle}}>Previous Invoice Periods Adjustments</div>
                        </div>
                      ) : null}

                      {payrollIndex === 0 ? (
                        <div style={{width: '100%'}}>
                          <div style={sectionDateDivider}>{previousPayrollShiftDate}</div>
                      
                          <div style={professionalTableStyle}>
                            <div style={professionalTableRow}>
                              <div style={{
                                ...professionalTableFirstHighlightCell,
                                width: columnWidth.profName,
                              }}>Name</div>
                              <div style={{
                                ...professionalTableCenteredHighlightCell,
                                width: columnWidth.type,
                              }}>Type</div>
                              <div style={{
                                ...professionalTableCenteredHighlightCell,
                                width: columnWidth.clock,
                              }}>Clock-In</div>
                              <div style={{
                                ...professionalTableCenteredHighlightCell,
                                width: columnWidth.clock,
                              }}>Clock-Out</div>
                              <div style={{
                                ...professionalTableNumericHighlightCell,
                                width: columnWidth.totalBreaks,
                              }}>Break Minutes</div>
                              <div style={{
                                ...professionalTableHighlightCell,
                                width: columnWidth.supervisor,
                              }}>Clock-In Supervisor</div>
                              <div style={{
                                ...professionalTableHighlightCell,
                                width: columnWidth.supervisor,
                              }}>Clock-In Signature</div>
                              <div style={{
                                ...professionalTableHighlightCell,
                                width: columnWidth.supervisor,
                              }}>Clock-Out Supervisor</div>
                              <div style={{
                                ...professionalTableHighlightCell,
                                borderRight: '0.5px solid black',
                                width: columnWidth.supervisor,
                              }}>Clock-Out Signature</div>

                              <div style={professionalTableSpacer}></div>

                              <div style={{
                                ...professionalTableHighlightCell,
                                borderLeft: '0.5px solid black',
                                width: columnWidth.payType
                              }}>Pay Type</div>
                              <div style={{
                                ...professionalTableNumericHighlightCell,
                                width: columnWidth.hours
                              }}>Hours</div>
                              <div style={{
                                ...professionalTableNumericHighlightCell,
                                width: columnWidth.rate
                              }}>Rate</div>
                              <div style={{
                                ...professionalTableNumericHighlightCell,
                                borderRight: '0.5px solid black',
                                width: columnWidth.amount
                              }}>Amount</div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div style={professionalTableStyle}>
                        <div style={{
                          ...professionalTableRow,
                          ...(payrollIndex % 2 > 0 ? professionalTableOddRow : {}),
                          height: subRowHeight * rowsCount,
                        }}>
                          <div style={{
                            ...professionalTableFirstTextCell,
                            width: columnWidth.profName,
                          }}>{previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].prof_full_name}</div>
                          <div style={{
                            ...professionalTableCenteredCell,
                            width: columnWidth.type,
                          }}>{previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].job_type_abbrev}</div>
                          <div style={{
                            ...professionalTableCenteredCell,
                            width: columnWidth.clock,
                          }}>{
                            isNil(previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].adj_clock_in) 
                              ? '' 
                              : moment(previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].adj_clock_in).utc().format('h:mmA')
                          }</div>
                          <div style={{
                            ...professionalTableCenteredCell,
                            width: columnWidth.clock,
                          }}>{
                            isNil(previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].adj_clock_out) 
                              ? '' 
                              : moment(previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].adj_clock_out).utc().format('h:mmA')
                          }</div>
                          <div style={{
                            ...professionalTableNumericCell,
                            width: columnWidth.totalBreaks,
                          }}>{previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].total_break}</div>
                          <div style={{
                            ...professionalTableTextCell,
                            width: columnWidth.supervisor,
                            flexDirection: 'column',
                          }}>
                            <div style={{
                              flex: 1,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                              {previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].clock_in_supervisor_full_name}
                            </div>
                          </div>
                          <div style={{
                            ...professionalTableTextCell,
                            width: columnWidth.supervisor,
                            flexDirection: 'column',
                          }}>
                            <div style={signatureCellStyle}>
                              <img 
                                src={signatureUrls[previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].clock_in_signature_file_path]}
                                style={{
                                  height: subRowHeight - 2,
                                  display: 'block',
                                  alignSelf: 'center',
                                }} 
                              />
                            </div>
                          </div>
                          <div style={{
                            ...professionalTableTextCell,
                            width: columnWidth.supervisor,
                            flexDirection: 'column',
                          }}>
                            <div style={{
                              flex: 1,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                              {previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].clock_out_supervisor_full_name}
                            </div>
                          </div>
                          <div style={{
                            ...professionalTableTextCell,
                            borderRight: '0.5px solid black',
                            width: columnWidth.supervisor,
                            flexDirection: 'column',
                          }}>
                            <div style={signatureCellStyle}>
                              <img 
                                src={signatureUrls[previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].clock_out_signature_file_path]}
                                style={{
                                  height: subRowHeight - 2,
                                  display: 'block',
                                  alignSelf: 'center',
                                }} 
                              />
                            </div>
                          </div>

                          <div style={professionalTableSpacer}></div>
                          
                          <div style={{
                            ...professionalSubTable,
                          }}>

                            {previousPayrolls.dates[previousPayrollShiftDate].items[payrollKey].sub_payrolls.map((sub_payroll, sub_payroll_index, sub_payrolls_array) => (
                              <div key={sub_payroll.sub_payroll_key} style={{
                                ...professionalTableSubRow,
                                ...(
                                  sub_payroll_index === 0 
                                  ? {
                                      borderTop: '0.125px solid black'
                                    } 
                                  : {}
                                ),
                                ...(
                                  sub_payroll_index === sub_payrolls_array.length - 1 
                                  ? {
                                      borderBottom: '0.125px solid black'
                                    } 
                                  : {}
                                ),
                              }}>
                                <div style={{
                                  ...professionalTableTextCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.payType,
                                }}>{sub_payroll.pay_type_name}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.hours,
                                }}>{sub_payroll.has_worked_hours ? sub_payroll.hours_worked : '-'}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.rate,
                                }}>{sub_payroll.has_worked_hours ? `$${sub_payroll.hourly_rate}` : '-'}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.amount,
                                  borderRight: '0.5px solid black',
                                }}>{isNil(sub_payroll.pay_amount) ? '' : `$${formatCurrency(sub_payroll.pay_amount)}`}</div>
                              </div>
                            ))}
                            
                          </div>
                        </div>

                        {payrollIndex === array.length - 1 ? (
                          <div style={{
                            ...professionalTableRow,
                            ...((payrollIndex + 1) % 2 > 0 ? professionalTableOddRow : {}),
                            height: subRowHeight * rowsCount,
                            backgroundColor: '#cccccc',
                          }}>
                            <div style={{
                              ...professionalTableFirstTextCell,
                              width: columnWidth.profName,
                              ...professionalTableLastRowCell
                            }}>{'Sub-total'}</div>
                            <div style={{
                              ...professionalTableCenteredCell,
                              width: columnWidth.type,
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableCenteredCell,
                              width: columnWidth.clock,
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableCenteredCell,
                              width: columnWidth.clock,
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableNumericCell,
                              width: columnWidth.totalBreaks,
                              ...professionalTableLastRowCell
                            }}>{previousPayrolls.dates[previousPayrollShiftDate].total_breaks}</div>
                            <div style={{
                              ...professionalTableTextCell,
                              width: columnWidth.supervisor,
                              flexDirection: 'column',
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableTextCell,
                              width: columnWidth.supervisor,
                              flexDirection: 'column',
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableTextCell,
                              width: columnWidth.supervisor,
                              flexDirection: 'column',
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableTextCell,
                              borderRight: '0.5px solid black',
                              width: columnWidth.supervisor,
                              flexDirection: 'column',
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
  
                            <div style={professionalTableSpacer}></div>
                            
                            <div style={{
                              ...professionalSubTable,
                              ...professionalTableLastRowCell
                            }}>
                              <div style={{
                                ...professionalTableSubRow,
                                borderTop: '0.125px solid black',
                                borderBottom: '0.125px solid black',
                              }}>
                                <div style={{
                                  ...professionalTableTextCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.payType,
                                }}>{' '}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.hours,
                                }}>{formatCurrency(previousPayrolls.dates[previousPayrollShiftDate].total_hours)}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.rate,
                                }}>{' '}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.amount,
                                  borderRight: '0.5px solid black',
                                }}>{`$${formatCurrency(previousPayrolls.dates[previousPayrollShiftDate].total_amount)}`}</div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {shiftDateIndex === mainArray.length - 1 && payrollIndex === array.length - 1 ? (
                        <div style={{width: '100%'}}>
                          <div 
                            style={{
                              ...sectionTitleStyle, 
                              fontSize: '8px',
                              textAlign: 'right',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            Previous Periods Adjustments - {formatCurrency(previousPayrolls.total_hours)} hours - Total: ${formatCurrency(previousPayrolls.total_amount)}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </body>
                </html>
              )}
            </Html>
          </View>
        )
      }))}

      {Object.keys(mainPayrolls.dates).map((mainPayrollShiftDate, shiftDateIndex, mainArray) => Object.keys(mainPayrolls.dates[mainPayrollShiftDate].items).map((payrollKey, payrollIndex, array) => {
        const profFullNameNeededLines = Math.ceil(mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].prof_full_name.length / 26)
        const clockInSupervisorFullNameNeededLines = Math.ceil(mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].clock_in_supervisor_full_name.length / 20)
        const clockOutSupervisorFullNameNeededLines = Math.ceil(mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].clock_out_supervisor_full_name.length / 20)
        const subRowsLength = mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].sub_payrolls.length

        const rowsCount = Math.max(...[
          profFullNameNeededLines,
          clockInSupervisorFullNameNeededLines,
          clockOutSupervisorFullNameNeededLines,
          subRowsLength,
        ])

        return (
          <View wrap={false} key={payrollKey}>
            <Html resetStyles>
              {ReactDOMServer.renderToStaticMarkup(
                <html>
                  <body style={bodyStyle}>
                    <div style={mainContentStyle}>

                      {shiftDateIndex === 0 && payrollIndex === 0 ? (
                        <div style={{width: '100%'}}>
                          <div style={{...sectionTitleStyle}}>Service Date Range: {invoice.week_start_date} to {invoice.week_end_date}</div>
                        </div>
                      ) : null}

                      {payrollIndex === 0 ? (
                        <div style={{width: '100%'}}>
                          <div style={sectionDateDivider}>{mainPayrollShiftDate}</div>

                          <div style={professionalTableStyle}>
                            <div style={professionalTableRow}>
                              <div style={{
                                ...professionalTableFirstHighlightCell,
                                width: columnWidth.profName,
                              }}>Name</div>
                              <div style={{
                                ...professionalTableCenteredHighlightCell,
                                width: columnWidth.type,
                              }}>Type</div>
                              <div style={{
                                ...professionalTableCenteredHighlightCell,
                                width: columnWidth.clock,
                              }}>Clock-In</div>
                              <div style={{
                                ...professionalTableCenteredHighlightCell,
                                width: columnWidth.clock,
                              }}>Clock-Out</div>
                              <div style={{
                                ...professionalTableNumericHighlightCell,
                                width: columnWidth.totalBreaks,
                              }}>Break Minutes</div>
                              <div style={{
                                ...professionalTableHighlightCell,
                                width: columnWidth.supervisor,
                              }}>Clock-In Supervisor</div>
                              <div style={{
                                ...professionalTableHighlightCell,
                                width: columnWidth.supervisor,
                              }}>Clock-In Signature</div>
                              <div style={{
                                ...professionalTableHighlightCell,
                                width: columnWidth.supervisor,
                              }}>Clock-Out Supervisor</div>
                              <div style={{
                                ...professionalTableHighlightCell,
                                borderRight: '0.5px solid black',
                                width: columnWidth.supervisor,
                              }}>Clock-Out Signature</div>

                              <div style={professionalTableSpacer}></div>

                              <div style={{
                                ...professionalTableHighlightCell,
                                borderLeft: '0.5px solid black',
                                width: columnWidth.payType
                              }}>Pay Type</div>
                              <div style={{
                                ...professionalTableNumericHighlightCell,
                                width: columnWidth.hours
                              }}>Hours</div>
                              <div style={{
                                ...professionalTableNumericHighlightCell,
                                width: columnWidth.rate
                              }}>Rate</div>
                              <div style={{
                                ...professionalTableNumericHighlightCell,
                                borderRight: '0.5px solid black',
                                width: columnWidth.amount
                              }}>Amount</div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div style={professionalTableStyle}>
                        <div style={{
                          ...professionalTableRow,
                          ...(payrollIndex % 2 > 0 ? professionalTableOddRow : {}),
                          height: subRowHeight * rowsCount,
                        }}>
                          <div style={{
                            ...professionalTableFirstTextCell,
                            width: columnWidth.profName,
                          }}>{mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].prof_full_name}</div>
                          <div style={{
                            ...professionalTableCenteredCell,
                            width: columnWidth.type,
                            ...(payrollIndex === array.length - 1 ? professionalTableLastRowCell : {})
                          }}>{mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].job_type_abbrev}</div>
                          <div style={{
                            ...professionalTableCenteredCell,
                            width: columnWidth.clock,
                            ...(payrollIndex === array.length - 1 ? professionalTableLastRowCell : {})
                          }}>{
                            isNil(mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].adj_clock_in)
                              ? ''
                              : moment(mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].adj_clock_in).utc().format('h:mmA')
                          }</div>
                          <div style={{
                            ...professionalTableCenteredCell,
                            width: columnWidth.clock,
                            ...(payrollIndex === array.length - 1 ? professionalTableLastRowCell : {})
                          }}>{
                            isNil(mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].adj_clock_out)
                              ? ''
                              : moment(mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].adj_clock_out).utc().format('h:mmA')
                          }</div>
                          <div style={{
                            ...professionalTableNumericCell,
                            width: columnWidth.totalBreaks,
                            ...(payrollIndex === array.length - 1 ? professionalTableLastRowCell : {})
                          }}>{mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].total_break}</div>
                          <div style={{
                            ...professionalTableTextCell,
                            width: columnWidth.supervisor,
                            flexDirection: 'column',
                            ...(payrollIndex === array.length - 1 ? professionalTableLastRowCell : {})
                          }}>
                            <div style={{
                              flex: 1,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                              {mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].clock_in_supervisor_full_name}
                            </div>
                          </div>
                          <div style={{
                            ...professionalTableTextCell,
                            width: columnWidth.supervisor,
                            flexDirection: 'column',
                            ...(payrollIndex === array.length - 1 ? professionalTableLastRowCell : {})
                          }}>
                            <div style={signatureCellStyle}>
                              <img 
                                src={signatureUrls[mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].clock_in_signature_file_path]}
                                style={{
                                  height: subRowHeight - 2,
                                  display: 'block',
                                  alignSelf: 'center',
                                }} 
                              />
                            </div>
                          </div>
                          <div style={{
                            ...professionalTableTextCell,
                            width: columnWidth.supervisor,
                            flexDirection: 'column',
                            ...(payrollIndex === array.length - 1 ? professionalTableLastRowCell : {})
                          }}>
                            <div style={{
                              flex: 1,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                              {mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].clock_out_supervisor_full_name}
                            </div>
                          </div>
                          <div style={{
                            ...professionalTableTextCell,
                            borderRight: '0.5px solid black',
                            width: columnWidth.supervisor,
                            flexDirection: 'column',
                            ...(payrollIndex === array.length - 1 ? professionalTableLastRowCell : {})
                          }}>
                            <div style={signatureCellStyle}>
                              <img 
                                src={signatureUrls[mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].clock_out_signature_file_path]}
                                style={{
                                  height: subRowHeight - 2,
                                  display: 'block',
                                  alignSelf: 'center',
                                }} 
                              />
                            </div>
                          </div>

                          <div style={professionalTableSpacer}></div>
                          
                          <div style={{
                            ...professionalSubTable,
                            ...(payrollIndex === array.length - 1 ? professionalTableLastRowCell : {})
                          }}>

                            {mainPayrolls.dates[mainPayrollShiftDate].items[payrollKey].sub_payrolls.map((sub_payroll, sub_payroll_index, sub_payrolls_array) => (
                              <div key={sub_payroll.sub_payroll_key} style={{
                                ...professionalTableSubRow,
                                ...(
                                  sub_payroll_index === 0 
                                  ? {
                                      borderTop: '0.125px solid black'
                                    } 
                                  : {}
                                ),
                                ...(
                                  sub_payroll_index === sub_payrolls_array.length - 1 
                                  ? {
                                      borderBottom: '0.125px solid black'
                                    } 
                                  : {}
                                ),
                              }}>
                                <div style={{
                                  ...professionalTableTextCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.payType,
                                }}>{sub_payroll.pay_type_name}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.hours,
                                }}>{sub_payroll.has_worked_hours ? sub_payroll.hours_worked : '-'}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.rate,
                                }}>{sub_payroll.has_worked_hours ? `$${sub_payroll.hourly_rate}` : '-'}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.amount,
                                }}>{isNil(sub_payroll.pay_amount) ? '' : `$${formatCurrency(sub_payroll.pay_amount)}`}</div>
                              </div>
                            ))}

                          </div>
                        </div>

                        {payrollIndex === array.length - 1 ? (
                          <div style={{
                            ...professionalTableRow,
                            ...((payrollIndex + 1) % 2 > 0 ? professionalTableOddRow : {}),
                            height: subRowHeight * rowsCount,
                            backgroundColor: '#cccccc',
                          }}>
                            <div style={{
                              ...professionalTableFirstTextCell,
                              width: columnWidth.profName,
                              ...professionalTableLastRowCell
                            }}>{'Sub-total'}</div>
                            <div style={{
                              ...professionalTableCenteredCell,
                              width: columnWidth.type,
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableCenteredCell,
                              width: columnWidth.clock,
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableCenteredCell,
                              width: columnWidth.clock,
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableNumericCell,
                              width: columnWidth.totalBreaks,
                              ...professionalTableLastRowCell
                            }}>{mainPayrolls.dates[mainPayrollShiftDate].total_breaks}</div>
                            <div style={{
                              ...professionalTableTextCell,
                              width: columnWidth.supervisor,
                              flexDirection: 'column',
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableTextCell,
                              width: columnWidth.supervisor,
                              flexDirection: 'column',
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableTextCell,
                              width: columnWidth.supervisor,
                              flexDirection: 'column',
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
                            <div style={{
                              ...professionalTableTextCell,
                              borderRight: '0.5px solid black',
                              width: columnWidth.supervisor,
                              flexDirection: 'column',
                              ...professionalTableLastRowCell
                            }}>{' '}</div>
  
                            <div style={professionalTableSpacer}></div>
                            
                            <div style={{
                              ...professionalSubTable,
                              ...professionalTableLastRowCell
                            }}>
                              <div style={{
                                ...professionalTableSubRow,
                                borderTop: '0.125px solid black',
                                borderBottom: '0.125px solid black',
                              }}>
                                <div style={{
                                  ...professionalTableTextCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.payType,
                                }}>{' '}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.hours,
                                }}>{formatCurrency(mainPayrolls.dates[mainPayrollShiftDate].total_hours)}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.rate,
                                }}>{' '}</div>
                                <div style={{
                                  ...professionalTableNumericCell,
                                  ...professionalTableSubRowCell,
                                  width: columnWidth.amount,
                                  borderRight: '0.5px solid black',
                                }}>{`$${formatCurrency(mainPayrolls.dates[mainPayrollShiftDate].total_amount)}`}</div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {shiftDateIndex === mainArray.length - 1 && payrollIndex === array.length - 1 ? (
                        <div style={{width: '100%'}}>
                          <div 
                            style={{
                              ...sectionTitleStyle, 
                              fontSize: '8px',
                              textAlign: 'right',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            Service Date Range: {invoice.week_start_date} to {invoice.week_end_date} - {formatCurrency(mainPayrolls.total_hours)} hours - Total: ${formatCurrency(mainPayrolls.total_amount)}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </body>
                </html>
              )}
            </Html>
          </View>
        )
      }))}

      <View wrap={false} fixed style={footerViewStyle}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(<SecondPageFooter companyAddress={companyAddress} />)}
        </Html>
      </View>
    </Page>
  )
}

export default memo(SecondPage)
