import { Box, Radio, Text, VStack } from "@chakra-ui/react"
import { FC, memo } from "react"

interface RadioAddressSuggestionOptionProps {
  label: string
  address: string
  cityStateZip: string
  value: string
  isSelected?: boolean
}

const RadioAddressSuggestionOption: FC<RadioAddressSuggestionOptionProps> = ({label, address, cityStateZip, value, isSelected}) => {
  return (
    <VStack width={'full'} gap={1} alignItems={'flex-start'} borderWidth={1} borderColor={isSelected ? 'orange.400' : 'gray.300'} borderStyle={'solid'} rounded={'md'} p={2}>
      <Text fontWeight={'bold'}>{label}</Text>
      <Radio value={value} colorScheme="orange">
        <VStack gap={0} alignItems={'flex-start'}>
          <Box>{address}</Box>
          <Box>{cityStateZip}</Box>
        </VStack>
      </Radio>
    </VStack>
  )
}

export default memo(RadioAddressSuggestionOption)
