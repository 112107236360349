import { CSSProperties, FC, memo, useCallback, useMemo } from "react";

export type PastDueWarningLevel = 0 | 1 | 2 | 3;

const FirstPageFooter: FC<{pastDueWarningLevel: PastDueWarningLevel}> = ({
  pastDueWarningLevel,
}) => {
  const bodyStyle = useMemo((): CSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginRight: 8
  }), [])

  const highlightMessage = useMemo((): CSSProperties => ({
    width: "100%",
    textAlign: "center",
    fontSize: 12,
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#d23132"
  }), [])
  
  const highlightMessageLine: CSSProperties = useMemo(() => ({
    marginBottom: 4
  }), []);
  
  const lastMessage: CSSProperties = useMemo(() => ({
    width: "100%",
    textAlign: "center",
    fontSize: 12,
    marginTop: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }), []);

  const lastMessageLine: CSSProperties = useMemo(() => ({
    marginBottom: 4
  }), []);
  
  const getWarningMessage = useCallback(() => {
    switch (pastDueWarningLevel) {
      case 1:
        return (
          <div style={highlightMessage}>
            <div style={highlightMessageLine}>Your account is past due and requires immediate attention.</div>
            <div style={highlightMessageLine}>Promptly remit payment to settle your outstanding balance and avoid additional late fee charges.</div>
          </div>
        )
      case 2:
        return (
          <div style={highlightMessage}>
            <div style={highlightMessageLine}>Your account is significantly past due and requires prompt action.</div>
            <div style={highlightMessageLine}>Remit payment immediately to ensure continued service and avoid accruing late fees.</div>
          </div>
        )
      case 3:
        return (
          <div style={highlightMessage}>
            <div style={highlightMessageLine}>Urgent action is required to expedite payment and settle your outstanding balance.</div>
            <div style={highlightMessageLine}>Failure to promptly address this situation may result in potential service disruptions.</div>
          </div>
        )
      default:
        return null
    }
  }, [pastDueWarningLevel, highlightMessage, highlightMessageLine])
  
  return (
    <html>
      <body style={bodyStyle}>
        {getWarningMessage()}

        <div style={lastMessage}>
          <div style={lastMessageLine}>THANK YOU FOR YOUR BUSINESS</div>
        </div>
      </body>
    </html>
  )
}

export default memo(FirstPageFooter);
