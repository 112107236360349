import { Page, View } from "@react-pdf/renderer"
import { FC, memo } from "react"
import ReactDOMServer from "react-dom/server"
import Html from "react-pdf-html"
import { convertAggrupedObjectsToRows } from "../.."
import { PayStubProfessionalPayment } from "../../../../../../@types/professional"
import FirstPageDocumentInfo from "./FirstPageDocumentInfo"
import PayStubAdjustmentsTable from "./PayStubAdjustmentsTable"
import PayStubDetailsTable from "./PayStubDetailsTable"
import PayStubFirstPageHeader from "./PayStubFirstPageHeader"
import PayStubPaymentsTable from "./PayStubPaymentsTable"

const PayStubFirstPage: FC<PayStubProfessionalPayment> = (data) => {
  const details = convertAggrupedObjectsToRows(data.shifts)
  const adjustments = convertAggrupedObjectsToRows(data.adjustments)

  return (
    <Page size='LETTER' orientation="portrait" style={{ paddingVertical: 20, paddingHorizontal: 8 }}>
      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <PayStubFirstPageHeader />
          )}
        </Html>
      </View>

      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageDocumentInfo 
              professionalFullName={data.professional_full_name} 
              street={data.professional_street}
              city={data.professional_city}
              state={data.professional_state}
              zip={data.professional_zip}
              professionalId={data.professional_id}
              payPeriod={data.payPeriod}
              jobTitle={data.professional_job_title}
            />
          )}
        </Html>
      </View>

      {details.map((item, index, array) => (
        <View key={item.key} wrap={false}>
          <Html resetStyles>
            {ReactDOMServer.renderToStaticMarkup(
              <PayStubDetailsTable 
                details={item} 
                index={index} 
                showFacilityHeader={index === 0 || array[index - 1].facility_id !== item.facility_id}
                showTotals={index === array.length - 1 || array[index + 1].facility_id !== item.facility_id}
              />
            )}
          </Html>
        </View>
      ))}

      {adjustments.map((item, index, array) => (
        <View key={item.key} wrap={false}>
          <Html resetStyles>
            {ReactDOMServer.renderToStaticMarkup(
              <PayStubAdjustmentsTable
                adjustment={item}
                index={index}
                showFacilityHeader={index === 0 || array[index - 1].facility_id !== item.facility_id}
                showTotals={index === array.length - 1 || array[index + 1].facility_id !== item.facility_id}
              />
            )}
          </Html>
        </View>
      ))}

      {Object.values(data.payments).map((item, index, array) => (
        <View key={item.payment_transaction_id} wrap={false}>
          <Html resetStyles>
            {ReactDOMServer.renderToStaticMarkup(
              <PayStubPaymentsTable
                payment={item}
                totalAmount={data.total_amount}
                index={index}
                array={array}
              />
            )}
          </Html>
        </View>
      ))}

    </Page>
  )
}

export default memo(PayStubFirstPage)
