import { CSSProperties, FC, memo } from "react"
import { InvoiceRecord } from "../../../../../../../@types/facility"

const FirstPageMainSection: FC<{invoice: InvoiceRecord}> = ({invoice}) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }
  
  const invoiceDividerStyle: CSSProperties = {
    width: '100%', 
    height: 1, 
    backgroundColor: '#2b78e4',
    marginBottom: 10,
  }

  const invoiceInfoBoxStyle: CSSProperties = {
    backgroundColor: '#f2f2f2',
    borderRadius: '20px',
    border: '1px solid #d3d3d3',
    padding: 10,
    textAlign: 'center',
    fontSize: '10px',
    marginLeft: 10
  }

  const invoiceMainSectionStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 10,
    width: '100%',
  }

  const invoiceTableStyle: CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }

  const invoiceMainTableKeyStyle: CSSProperties = {
    fontSize: '10px',
    width: 100
  }

  const invoiceMainTableValueStyle: CSSProperties = {
    fontSize: '10px'
  }

  const invoiceMainTableRowStyle: CSSProperties = {
    display: 'flex', 
    flexDirection: 'row',
    marginBottom: 10
  }

  const forCreditToPieces = invoice.factor_for_credit_to?.replace(' Title:', '[###]Title:').split('[###]') ?? ['', '']

  return (
    <html>
      <body style={bodyStyle}>
        <div style={invoiceMainSectionStyle}>
          <div style={invoiceTableStyle}>
            <div style={invoiceMainTableRowStyle}>
              <div style={invoiceMainTableKeyStyle}>Bill from:</div>
              <div style={invoiceMainTableValueStyle}>Bravo Care</div>
            </div>

            <div style={invoiceMainTableRowStyle}>
              <div style={invoiceMainTableKeyStyle}>Bill to:</div>
              <div style={invoiceMainTableValueStyle}>
                {invoice.customer_name?.trim() ?? ''}
                <br />
                {invoice.customer_street?.trim() ?? ''}
                <br />
                {invoice.customer_city?.trim() ?? ''}, {invoice.customer_state?.trim() ?? ''} {invoice.customer_zip?.trim() ?? ''}
              </div>
            </div>

            <div style={invoiceMainTableRowStyle}>
              <div style={invoiceMainTableKeyStyle}>Remit Payment to:</div>
              <div style={invoiceMainTableValueStyle}>
                <b>Regular Mail:</b>
                <br />
                {invoice.factor_cert_holder_reg_mail_name1?.trim() ?? ''}
                <br />
                {invoice.factor_cert_holder_reg_mail_name2?.trim() ?? ''}
                <br />
                {invoice.factor_cert_holder_reg_mail_address1?.trim() ?? ''}
                <br />
                {invoice.factor_cert_holder_reg_mail_city?.trim() ?? ''}, {invoice.factor_cert_holder_reg_mail_state?.trim() ?? ''} {invoice.factor_cert_holder_reg_mail_zip?.trim() ?? ''}
                <br />
                <br />
                <b>Expedited Mail:</b>
                <br />
                <i>{`(please make sure NOT to require a signature upon receipt)`}</i>
                <br />
                {invoice.factor_expedited_mail_name1?.trim() ?? ''} {invoice.factor_expedited_mail_name2?.trim() ?? ''}
                <br />
                {invoice.factor_expedited_mail_address1?.trim() ?? ''} {invoice.factor_expedited_mail_address2?.trim() ?? ''}
                <br />
                {invoice.factor_expedited_mail_city?.trim() ?? ''}, {invoice.factor_expedited_mail_state?.trim() ?? ''} {invoice.factor_expedited_mail_zip?.trim() ?? ''}
              </div>
            </div>
          </div>

          <div style={invoiceInfoBoxStyle}>
            <b>Please remit payment</b>
            <br />
            <b>through Wire to avoid</b>
            <br />
            <b>late fees on invoices</b>
            <br />
            <b>received</b>
            <br/> 
            <b>Wire funds to:</b>
            <br />
            {invoice.factor_company_name?.trim() ?? ''}
            <br />
            {invoice.factor_company_address1?.trim() ?? ''}
            <br />
            {invoice.factor_company_city?.trim() ?? ''}, {invoice.factor_company_state?.trim() ?? ''} {invoice.factor_company_zip?.trim() ?? ''}
            <br />
            ABA Routing Number:
            <br />
            {invoice.factor_aba_routing_no}
            <br />
            Account Number:
            <br />
            {invoice.factor_account_no}
            <br />
            For Credit to: {(forCreditToPieces[0] ?? '').replace('Account', '').replace('Title', '').replace(': ', '').replace(':', '').trim()}
            <br />
            {(forCreditToPieces[1] ?? '').replace('Account', '').replace('Title', '').replace(': ', '').replace(':', '').trim()}
          </div>
        </div>

        <div style={invoiceDividerStyle}></div>
      </body>
    </html>
  )
}

export default memo(FirstPageMainSection)
