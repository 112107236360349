import { BlobProvider, Document, PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import moment from 'moment-timezone';
import { useCallback, useState } from "react";
import { Statement } from "../../../../@types/organization";
import StatementFirstPage from "./Pages/StatementFirstPage";

const API_ROOT = '/api/v1';

export const formatHours = (hours) => {
  return moment(hours, 'HH:mm').format('hh:mm A')
}

const StatementPDF = ({data}) => {
  return (
    <Document>
      <StatementFirstPage {...data} />
    </Document>
  )
}

export const StatementPDFViewer = ({data}) => {
  return (
    <div style={{
      width: '100%',
      height: 'auto'
    }}>
      <PDFViewer 
        showToolbar={true}
        style={{ 
          width: '100%',
          height: '100vh'
        }}
      >
        <StatementPDF data={data} />
      </PDFViewer>
    </div>
  )
}

export const StatementPDFGenerator = () => {
  const [pdfToken, setPDFToken] = useState('')
  const [authToken, setAuthToken] = useState('')
  const [data, setData] = useState<Statement>()
  const [facilityApiURL, setFacilityApiURL] = useState('')

  const saveBlob = useCallback((blob: Blob | null) => {
    // TODO: throw an error in case blob is null
    if (!blob || !data) return

    const file = new File([blob], `Statement.pdf`)
    const formData = new FormData()
    formData.set('file', file)
    formData.append('token', pdfToken)
    formData.append('facilityApiURL', facilityApiURL)
    formData.append('id', data.id.toString())

    axios.post(`${API_ROOT}/organization/saveGeneratedStatementPDF`, formData, {
      headers: {
        'authorization': authToken,
        'Content-Type': `multipart/form-data`
      },
    })
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.error(e)
      })
  }, [pdfToken, authToken, facilityApiURL, data])

  const setTokens = useCallback(() => {
    const authToken = (document.getElementById('auth-token-input') as HTMLInputElement)?.value ?? ''
    const pdfToken = (document.getElementById('pdf-token-input') as HTMLInputElement)?.value ?? ''
    const data = (document.getElementById('data-input') as HTMLInputElement)?.value ?? ''
    const facilityApiURL = (document.getElementById('facility-api-url-input') as HTMLInputElement)?.value ?? ''
    const companyAddress = JSON.parse((document.getElementById('set-company-address') as HTMLInputElement)?.value ?? '{}')

    setAuthToken(authToken)
    setPDFToken(pdfToken)
    setFacilityApiURL(facilityApiURL)
    setData({
      ...(JSON.parse(data)),
      companyAddress
    })
  }, [])

  if (!authToken || authToken.length === 0 || !pdfToken || pdfToken.length === 0 || !data || !facilityApiURL || facilityApiURL.length === 0) {
    return (
      <div>
        <input id='auth-token-input' placeholder="auth-token-input" />
        <input id='pdf-token-input' placeholder="pdf-token-input" />
        <input id='data-input' placeholder="data-input" />
        <input id='facility-api-url-input' placeholder="facility-api-url-input" />
        <input id='set-company-address' placeholder="set-company-address" />
        <button id="set-tokens-btn" onClick={setTokens}>
          Set Tokens
        </button>
      </div>
    )
  }

  if (!data || !facilityApiURL || facilityApiURL.length === 0) {
    return <div id='loading'>Loading PDF...</div>
  }

  return (
    <div>
      <BlobProvider document={<StatementPDF data={data} />}>
        {({ blob, loading, error }) => {
            if (loading) {
              return <div id="loading">Loading PDF...</div>
            }

            if (error) {
              return <div id="error">{error.message}</div>
            }

            return (
              <button id="save-pdf-button" onClick={() => saveBlob(blob)}>
                Save PDF
              </button>
            )
          }
        }
      </BlobProvider>

      <StatementPDFViewer data={data} />
    </div>
  )
}
