import { isNil } from "lodash"
import moment from "moment"
import { CSSProperties, FC, memo } from "react"
import { InvoiceJobTypeSummaryRecord } from "../../../../../../../@types/facility"
import { formatCurrency } from "../../../../../helpers/number.helper"

const BreakdownTable: FC <{
  invoiceWeekEndDate: string,
  invoiceWeekStartDate: string, 
  jobTypes: InvoiceJobTypeSummaryRecord[]
}> = ({invoiceWeekEndDate, invoiceWeekStartDate, jobTypes}) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 15,
    width: '100%'
  }

  const invoiceMainTableRowStyle: CSSProperties = {
    display: 'flex', 
    flexDirection: 'row',
    marginBottom: 10,
    width: '100%'
  }

  const invoiceBreakdownTableStyle: CSSProperties = {
    fontSize: '10px',
    marginBottom: 10,
    width: '100%'
  }

  const invoiceBreakdownHeader: CSSProperties = {
    backgroundColor: '#2b78e4',
    color: 'white',
    padding: 2,
    border: '1px solid #CCCCCC',
    width: '100%'
  }

  const invoiceBreakdownTableRow: CSSProperties = {
    ...invoiceMainTableRowStyle,
    marginBottom: 0,
    backgroundColor: 'white',
    borderLeft: '1px solid #CCCCCC',
    borderRight: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  }

  const invoiceBreakdownTableCell: CSSProperties = {
    padding: 2,
    borderLeft: '1px solid #CCCCCC',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
  }

  const invoiceBreakdownTableFirstCell: CSSProperties = {
    ...invoiceBreakdownTableCell,
    borderLeftWidth: 0,
    justifyContent: 'flex-start',
    textAlign: 'left',
    flex: 1,
  }

  const {jobTotalFees, jobTotalHours, jobTotalNonWorkedHours, jobTotalStaffedShifts} = jobTypes.reduce((result, job) => {
    result.jobTotalFees += parseFloat((!isNil(job.total_fees) ? job.total_fees : 0).toString())
    result.jobTotalHours += parseFloat((!isNil(job.total_hours) ? job.total_hours : 0).toString())
    result.jobTotalNonWorkedHours += parseFloat((!isNil(job.total_non_worked_hours) ? job.total_non_worked_hours : 0).toString())
    result.jobTotalStaffedShifts += parseInt((!isNil(job.staffed_shifts) ? job.staffed_shifts : 0).toString())

    return result
  }, {jobTotalFees: 0.00, jobTotalHours: 0.00, jobTotalNonWorkedHours: 0.00, jobTotalStaffedShifts: 0})

  const commonColumnWidth = 100

  return (
    <html>
      <body style={bodyStyle}>
        <div style={invoiceBreakdownTableStyle}>
          <div style={invoiceBreakdownHeader}>
            Breakdown By Clinician Type (Service Date Range: {moment(invoiceWeekStartDate).format('MM/DD/YYYY')} to {moment(invoiceWeekEndDate).format('MM/DD/YYYY')}):
          </div>

          <div style={invoiceBreakdownTableRow}>
            <div style={invoiceBreakdownTableFirstCell}><u>Clinician Type</u></div>
            <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}><u>Total Fees</u></div>
            <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}><u>Total Worked Hours</u></div>
            <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}><u>Total Cancel. Pay Hours</u></div>
            <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}><u>Hours % of Total</u></div>
            <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}><u>Total Shifts Staffed</u></div>
          </div>

          {jobTypes.map((job) => (
            <div key={job.job_type_abbrev} style={invoiceBreakdownTableRow}>
              <div style={invoiceBreakdownTableFirstCell}>{job.job_type_abbrev}</div>
              <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}>${formatCurrency(!isNil(job.total_fees) ? job.total_fees : 0)}</div>
              <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}>{formatCurrency(!isNil(job.total_hours) ? job.total_hours : 0)}</div>
              <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}>{formatCurrency(!isNil(job.total_non_worked_hours) ? job.total_non_worked_hours : 0)}</div>
              <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}>{!isNil(job.hours_percent_of_total) ? job.hours_percent_of_total : '0'}%</div>
              <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}>{!isNil(job.staffed_shifts) ? job.staffed_shifts : '0'}</div>
            </div>
          ))}

          <div 
            style={{
              ...invoiceBreakdownTableRow,
              borderTop: '1px solid black',
            }}
          >
            <div style={invoiceBreakdownTableFirstCell}><b>Total</b></div>
            <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}><b>${formatCurrency(jobTotalFees)}</b></div>
            <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}><b>{formatCurrency(jobTotalHours)}</b></div>
            <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}><b>{formatCurrency(jobTotalNonWorkedHours)}</b></div>
            <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}><b>100%</b></div>
            <div style={{
              ...invoiceBreakdownTableCell,
              width: commonColumnWidth
            }}><b>{jobTotalStaffedShifts}</b></div>
          </div>
        </div>
      </body>
    </html>
  )
}

export default memo(BreakdownTable)
