import { Page, View } from "@react-pdf/renderer"
import { FC, createElement, memo } from "react"
import ReactDOMServer from "react-dom/server"
import Html from "react-pdf-html"
import { PayHistoryPayTypeSummary, PayHistoryPaymentDetail, PayHistoryShiftFacilitySummary, ProfessionalPayHistory } from "../../../../../../@types/professional"
import FirstPageDocumentInfo from "./FirstPageDocumentInfo"
import FirstPageHeader from "./FirstPageHeader"
import PayHistoryDetailsTable from "./PayHistoryDetailsTable"
import PayHistoryPaymentTable from "./PayHistoryPaymentTable"
import TotalTable from "./TotalTable"

const PayHistoryPDFFirstPage: FC<ProfessionalPayHistory> = (data) => {
  let rowStyles: 'even' | 'odd' = 'even'

  const tableItems = Object.values(data.payments).reduce((result: (Omit<PayHistoryPaymentDetail, 'summary'> | PayHistoryShiftFacilitySummary)[], payment) => {
    const {summary, ...paymentItem} = payment

    Object.keys(summary).forEach(shiftDate => {
      Object.values(summary[shiftDate]).forEach((payroll) => {
        // filter empty pay types
        payroll.payTypes = Object.values(payroll.payTypes).reduce((result: Record<string, PayHistoryPayTypeSummary>, payType) => {
          if (payType.amount !== 0.00) {
            result[payType.name] = payType
          }

          return result
        }, {})

        // if payTypes is empty, skip
        if (Object.keys(payroll.payTypes).length === 0) {
          return
        }

        payroll.rowStyles = rowStyles
        result.push(payroll)
      })
    })

    paymentItem.rowStyles = rowStyles
    result.push(paymentItem)

    rowStyles = rowStyles === 'even' ? 'odd' : 'even'

    return result
  }, [])

  tableItems.push({
    type: 'total',
    key: 'total',
    amount: data.total_transaction_amount,
    rowStyles: tableItems[tableItems.length - 1].rowStyles === 'even' ? 'odd' : 'even',
  } as any)

  return (
    <Page size='LETTER' orientation="portrait" style={{ paddingVertical: 20, paddingHorizontal: 8 }}>
      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageHeader firstDate={data.firstDate} />
          )}
        </Html>
      </View>

      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageDocumentInfo 
              professionalFullName={data.professional_full_name} 
              street={data.professional_street}
              city={data.professional_city}
              state={data.professional_state}
              zip={data.professional_zip}
              professionalId={data.professional_id}
              firstDate={data.firstDate}
              lastDate={data.lastDate}
              jobTitle={data.professional_job_title}
            />
          )}
        </Html>
      </View>

      {tableItems.map((item, index) => (
          <View key={item.key} wrap={false}>
            <Html resetStyles>
              {ReactDOMServer.renderToStaticMarkup(
                item.type === 'detail' ? (
                  <PayHistoryDetailsTable 
                    details={item as PayHistoryShiftFacilitySummary} 
                    index={index}
                  />
                ) : item.type === 'payment' ? (
                  <PayHistoryPaymentTable 
                    payment={item as Omit<PayHistoryPaymentDetail, 'summary'>}
                  />
                ) : item.type === 'total' ? (
                  <TotalTable
                    totalAmount={item.amount}
                    rowStyles={item.rowStyles}
                  />
                ) : createElement('html')
              )}
            </Html>
          </View>
        ))}

    </Page>
  )
}

export default memo(PayHistoryPDFFirstPage)
