import {CSSProperties} from 'react'

export const bodyStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
}

export const paymentMainTableRowStyle: CSSProperties = {
  display: 'flex', 
  flexDirection: 'row',
  marginBottom: 10,
  width: '100%',
}

export const payStubSection: CSSProperties = {
  fontSize: '10px',
  width: '100%',
  marginTop: 10,
  border: '1px solid black',
  backgroundColor: '#cccccc',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}

export const payStubItemHeader: CSSProperties = {
  fontSize: '10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: 5,
}

export const paymentDetailsTableRow: CSSProperties = {
  ...paymentMainTableRowStyle,
  fontSize: '10px',
  marginBottom: 0,
}

export const paymentDetailsTableHightlightRow: CSSProperties = {
  ...paymentDetailsTableRow,
  backgroundColor: '#cccccc',
  color: 'black',
  marginTop: 5,
  borderTop: '1px solid black',
}

export const paymentDetailsTableTotalsRow: CSSProperties = {
  ...paymentDetailsTableHightlightRow,
  backgroundColor: '#95bcf2',
  marginTop: 0,
  borderTop: 'none',
  borderBottom: '1px solid black',
}

export const paymentDetailsTableWhiteRow: CSSProperties = {
  ...paymentDetailsTableRow,
  backgroundColor: 'white',
}

export const paymentDetailsTableGrayRow: CSSProperties = {
  ...paymentDetailsTableWhiteRow,
  backgroundColor: '#eeeeee',
}

export const paymentDetailsTableCell: CSSProperties = {
  paddingLeft: 2,
  paddingRight: 2,
  paddingTop: 1,
  paddingBottom: 1,
  borderRight: '1px solid black',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  textAlign: 'right',
}

export const paymentDetailsTextTableCell: CSSProperties = {
  ...paymentDetailsTableCell,
  justifyContent: 'flex-start',
  textAlign: 'left'
}

export const paymentDetailsCenteredTableCell: CSSProperties = {
  ...paymentDetailsTableCell,
  justifyContent: 'center',
  textAlign: 'center'
}

export const paymentDetailsTableFirstCell: CSSProperties = {
  ...paymentDetailsTextTableCell,
  flex: 1,
  borderLeft: '1px solid black',
}

export const columnWidth = {
  hours: 60,
  amount: 100,
  fee: 50,
  transactionId: 100,
  paidVia: 60,
}
