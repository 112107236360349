import { CSSProperties } from "react"

export const bodyStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
}

export const paymentMainTableRowStyle: CSSProperties = {
  display: 'flex', 
  flexDirection: 'row',
  marginBottom: 10,
  width: '100%',
}

export const paymentMainRangeTextStyle: CSSProperties = {
  fontSize: '10px',
  marginBottom: 4,
}

export const paymentDetailsTableRow: CSSProperties = {
  ...paymentMainTableRowStyle,
  fontSize: '10px',
  marginBottom: 0,
}

export const paymentDetailsTableSubRowContainer: CSSProperties = {
  display: 'flex',	
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}

export const paymentDetailsTableSubRow: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}

export const paymentDetailsTableHightlightRow: CSSProperties = {
  ...paymentDetailsTableRow,
  backgroundColor: '#cccccc',
  color: 'black'
}

export const paymentDetailsTableWhiteRow: CSSProperties = {
  ...paymentDetailsTableRow,
  backgroundColor: 'white',
}

export const paymentDetailsTableGrayRow: CSSProperties = {
  ...paymentDetailsTableWhiteRow,
  backgroundColor: '#eeeeee',
}

export const paymentDetailsTableCell: CSSProperties = {
  paddingLeft: 2,
  paddingRight: 2,
  paddingTop: 1,
  paddingBottom: 1,
  borderLeft: '1px solid black',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  textAlign: 'right',
  minHeight: 15,
}

export const paymentDetailsTableDateCell: CSSProperties = {
  ...paymentDetailsTableCell,
  justifyContent: 'center',
  textAlign: 'center',
}

export const paymentDetailsTextTableCell: CSSProperties = {
  ...paymentDetailsTableCell,
  justifyContent: 'flex-start',
  textAlign: 'left'
}

export const paymentDetailsTableFirstCell: CSSProperties = {
  ...paymentDetailsTextTableCell,
  borderLeftWidth: 0,
  flex: 1,
}

export const columnWidth = {
  shiftDate: 58,
  hours: 65,
  amount: 60,
  payType: 100,
}
